import request from '@/utils/request'


//顶级角色联动列表
export function getChainslAuth() {
  return request({
    url: `/admin/auth/roleChains`,
    method: 'get',
  })
}

//add角色
export function addRole(data) {
  return request({
    url: `/admin/auth/role`,
    method: 'post',
    data
  })
}



//update角色
export function updateRole(id,data) {
  return request({
    url: `/admin/auth/role/${id}`,
    method: 'put',
    data
  })
}
// 档案审核列表
export function getContractExamineList(params) {
  return request({
    url: `/admin/contract/examineList`,
    method: 'GET',
    params
  })
}

// 档案审核
export function postContractExamineList(id) {
  return request({
    url: `/admin/contract/examine/${id}`,
    method: 'POST',
  })
}

export function getRoles() {
  return request({
    url: `/admin/auth/role/roles`,
    method: 'get',
  })
}

export function addAdmin(data) {
  return request({
    url: `/admin/auth/admin`,
    method: 'post',
    data
  })
}

export function updateAdmin(id,data) {
  return request({
    url: `/admin/auth/admin/${id}/update`,
    method: 'put',
    data
  })
}

//药房
export function pharmacyRoom() {
  return request({
    url: `/admin/auth/admin/pharmacyRoom`,
    method: 'get',
  })
}

//药师
export function pharmacist() {
  return request({
    url: `/admin/auth/admin/pharmacist`,
    method: 'get',
  })
}

//获取顶级商户列表
export function agents(type = 0) {
  return request({
    url: `/admin/topMerchants`,
    method: 'get',
    params:{type}
  })
}






